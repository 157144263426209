<template>
  <div class="DisassemblyDistribution">
    <div class="header">
      <span @click="previousPage"></span>
      <span>拆解接收</span>
      <span @click="goDistribution()">开始拆解</span>
    </div>
    <van-search
      v-model="value"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
	  style="margin-top: 50px;"
    >
      <select v-model="label" slot="label">
        <option value="carNumberPlate">车牌号：</option>
        <option value="defined_num">自定义编号：</option>
      </select>
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-pull-refresh class="refresh" v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="我是有底线的"
        :immediate-check="false"
        @load="onLoad"
        :offset="20"
      >
        <div
          class="Distribution_mains"
          v-for="(item, index) in tableData"
          @click="goDetail(item, index)"
          :class="{ bg: index == isactive }"
          :key="item.id"
        >
          <div class="main_right">
            <div>
              <span>自定义编码</span>
              <span class="characters">{{ item.defined_num }}</span>
              <!-- <input type="text" name="" id="" v-model="item.end_of_lifeVehiclesId" readonly="readonly"> -->
            </div>
            <div>
              <span>进场编号</span>
              <span class="characters">{{ item.end_of_lifeVehiclesId }}</span>
              <!-- <input type="text" name="" id="" v-model="item.end_of_lifeVehiclesId" readonly="readonly"> -->
            </div>

            <div>
              <span>车牌号</span>
              <span class="characters">{{ item.carNumberPlate }}</span>
              <!-- <input type="text"  v-model="item.carNumberPlate" readonly="readonly"> -->
            </div>
            <div>
              <span>品牌</span>
              <span class="characters">{{ item.vehicleBrand }}</span>
              <!-- <input type="text"  v-model="item.vehicleBrand" readonly="readonly"> -->
            </div>
            <!-- <div>
              <span>型号</span>
              <span class="characters">{{ item.vehicleModel }}</span>
              <input type="text"  v-model="item.vehicleModel" readonly="readonly">
            </div> -->
            <div>
              <span>拆解方式</span>
              <span class="characters">{{ item.disassemblyMethod }}</span>
              <!-- <input type="text"  v-model="item.disassemblyMethod" readonly="readonly"> -->
            </div>

            <div>
              <span>分配时间</span>
              <span class="characters">{{ item.createTime }}</span>
              <!-- <input type="text"  v-model="item.createTime" readonly="readonly"> -->
            </div>

            <div>
              <span>分配人</span>
              <span class="characters">{{ item.allocateName }}</span>
              <!-- <input type="text"  v-model="item.allocateName" readonly="readonly"> -->
            </div>

            <div>
              <span>是否监销</span>
              <span class="characters">{{ item.is_sale_of_text }}</span>
              <!-- <input type="text"  v-model="item.is_sale_of_text" readonly="readonly"> -->
            </div>
            <div>
              <span>备注</span>
              <span class="characters">{{ item.remark }}</span>
              <!-- <textarea type="textarea" style="width: 65%;"  v-model="item.remark" readonly="readonly"></textarea> -->
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <!-- <div></div> -->
  </div>
</template>
<script>
import { deleteCookie } from "../../utils/env";
import { encipherMent, encryptDecode } from "@/utils/encrypt";
export default {
  data() {
    return {
      checked: false,
      tableData: "",
      id: "",
      remark: "",
      isactive: 0, //默认第一个有样式
      ids: null,
      isLoading: false, //下拉刷新
      loading: false, // 上拉加载
      finished: false, // 上拉加载完毕
      page: 1, //页
      pageSize: 10, //每页大小
      tableData: [],
      value: "",
      label: "carNumberPlate",
    };
  },
  created() {
    this.lodData();
  },
  methods: {
    onSearch() {
		 this.page = 1
		this.lodData()
    },
    //上啦
    onRefresh() {
      this.finished = false;
      this.lodData(10, 1);
    },
    //下拉
    onLoad() {
      let this_ = this;
      setTimeout(() => {
        let page = this_.page + 1;
        this_.page = page;
        let pageSize = this_.pageSize;
        let data = encipherMent(JSON.stringify({ pageSize: pageSize,  key:this.label,value:this.value }));
        let param = new URLSearchParams();
        param.append("value", data);
        param.append("page", this_.page);
        this_.$http
          .post(
            "/index.php/index/Disassemble_Allocation/dismantleReceive",
            param
          )
          .then((res) => {
            let result = {};
            if (res.data.code == -1 || res.data.code == 9) {
              result = res.data;
            } else {
              result = JSON.parse(encryptDecode(res.data));
            }
            if (result.code == 0) {
              let arr = result.data.data;
              arr.forEach((element) => {
                this_.tableData.push(element);
              });
              if (this_.tableData.length >= result.data.total) {
                this.finished = true;
              } else {
                this.finished = false;
              }
              this.loading = false;
            }
          });
      }, 1000);
    },
    lodData() {
      let data = encipherMent(JSON.stringify({ pageSize: 10 ,key:this.label,value:this.value}));
      let param = new URLSearchParams();
      param.append("value", data);
      param.append("page", 1);
      this.$http
        .post("/index.php/index/Disassemble_Allocation/dismantleReceive", param)
        .then((res) => {
          let result = {};
          if (res.data.code == -1 || res.data.code == 9) {
            result = res.data;
          } else {
            result = JSON.parse(encryptDecode(res.data));
          }
          if (result.code == 0) {
            this.isLoading = false;
            if (result.data.data.length == 0) {
              this.finished = true;
              this.$toast.fail("无数据");
            //   this.$router.push("/guide");
              this.tableData=result.data.data;
            } else {
              this.finished = false;
              this.tableData = result.data.data;
              this.id = result.data.data[0].end_of_lifeVehiclesId;
              this.ids = result.data.data[0].id;
            }
          } else if (result.code == "-1") {
            this.$toast.fail(result.msg);
            this.$router.push("/login");
            deleteCookie("token");
            // window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
            //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{

            //       if(result.code == 0){
            //           let domain = this.zhuan(result.data)
            //           let url = window.location.origin

            //           window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
            //       }
            //   })
          } else {
            this.$toast.fail(result.msg);
          }
        });
    },
    zhuan(url) {
      let str = url;
      let str1 = str.replace("https", "");
      str1 = str1.replace("http", "");
      let str2 = "https" + str1;
      return str2;
    },
    goDetail(item, index) {
      //console.log(item, index);

      // if(this.$refs.check[index].value == true){
      // this.$refs.check[index].value = true
      // }else{
      //   this.$refs.check[index].value = true
      // }

      this.id = item.end_of_lifeVehiclesId;
      this.ids = item.id;

      this.isactive = index;
      //console.log(index);
    },
    goDistribution() {
      //  if(this.id != ''){
      this.$router.push({
        path: "DisassemblyRecord",
        query: { id: this.id, ids: this.ids },
      });

      // }else{
      //   this.$toast.fail("请选择完成后进行分配")
      // }
    },
    previousPage() {
      this.$router.push("/guide");
    },
  },
};
</script>
<style lang="scss" scope="this api replaced by slot-scope in 2.5.0+">
.DisassemblyDistribution {
  height: 100%;
  .bg {
    // color: red;
    background-color: #eee;
  }
  .header {
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    position: fixed;
    top: 0;
    width: calc(100% - 40px);
    z-index: 9;
    justify-content: space-between;
    span {
      display: inline-block;
    }
    span:nth-child(1) {
      width: 30px;
      height: 30px;
      background-image: url(../../assets/u39.png);
      background-size: 100%;
      margin-top: 8px;
    }
  }
  .Distribution_mains {
    width: 90%;
    overflow: hidden;
    margin: 20px auto;
    border: 1px solid;
    padding: 0.3rem;
    // flex-wrap: nowrap;

    .main_left {
      width: 10%;
      height: 100%;

      // border: 1px solid;
      // float: left;
    }
    .main_right {
      width: 100%;

      // border: 1px solid;
      font-size: 0.35rem;
      // float: left;
      div {
        margin-top: 10px;
        border-bottom: 1px solid #e7e0e0f5;
        span {
          width: 2.5rem;
          display: inline-block;
          text-align: center;
          margin-right: 23px;
        }
        .characters {
          width: 60%;
          display: inline-block;
          text-align: center;
          padding: 3px;
        }
        input {
          width: 60%;
          height: 0.5rem;
        }
      }
    }
  }
  .header {
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    position: fixed;
    top: 0;
    width: calc(100% - 40px);
    z-index: 9;
    justify-content: space-between;
    span {
      display: inline-block;
    }
    span:nth-child(1) {
      width: 30px;
      height: 30px;
      background-image: url(../../assets/u39.png);
      background-size: 100%;
      margin-top: 8px;
    }
  }
}
</style>
